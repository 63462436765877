import React from "react";
import AnyLink from "gatsby-plugin-transition-link/AniLink";


const Navigation = ({ menuLinkItems }) => {

  return (
    <nav className="menu-wrapper">
      <ul>
        {menuLinkItems.map(menuLinkItem => {
          const slug = menuLinkItem.slug === 'home-page' ? '/' : `/` + menuLinkItem.slug;
          return (
            <li key={menuLinkItem.slug}><AnyLink activeStyle={{ color:'#F19F78' }} fade duration={0.6} to={slug.trim()}>{menuLinkItem.pageTitle}</AnyLink></li>
          )
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
