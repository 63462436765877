
import PropTypes from "prop-types";
import React from "react";
import Navigation from './navigation';
import MobileNavigation from './mobile-navigaton';
import { Link } from 'gatsby';

import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap-grid.min.css';

import '../../styles/styles.scss';
import eventaLogo from '../../images/logo.svg';



const Header = ({ siteTitle, navigation }) => {
 
  return(
    <div>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/lco5mmp.css"></link>
      </Helmet>
      <header className="header">
        <div className="logo-wrapper">
          <Link to={'/'}><img src={eventaLogo} alt="The Eventa Group"/></Link>
        </div>
        <Navigation menuLinkItems={navigation} />
      </header>
      <MobileNavigation menuLinkItems={navigation} />
    </div>
  )};

Header.propTypes = {
  siteTitle: PropTypes.string,
  navigation: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  navigation: []
}

export default Header
