import AnyLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import { slide as Menu } from 'react-burger-menu';


const mobileNavigation = ({ menuLinkItems, props }) => {

  var styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '30px',
      height: '24px',
      right: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#ffffff'
    },
    bmBurgerBarsHover: {
      background: '#F19F78'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px',
      right: '34px',
    },
    bmCross: {
      background: '#F19F78'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '4em 1em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#fff',
      padding: '0.8em'
    },
    bmItem: {
      display: 'block',
      color: 'white',
      marginBottom: '30px',
      borderBottom: '1px solid #F19F78',
      textDecoration: 'none'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.6)',
    }
  }

  return (
    <Menu {...props} styles={ styles } className="mobile-menu" bodyClassName={ "menu-is-open" } isOpen={ false } width={ '60%' }  right disableAutoFocus>
         {menuLinkItems.map(menuLinkItem => {
          const slug = menuLinkItem.slug === 'home-page' ? '/' : `/` + menuLinkItem.slug;
          return (
            <AnyLink fade duration={0.5} activeStyle={{ color:'#F19F78' }} to={slug.trim()}>{menuLinkItem.pageTitle}</AnyLink>
          )
        })}
    </Menu>
  );
};

export default mobileNavigation;
