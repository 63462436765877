/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"

import "../../styles/styles.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulMainNavigation {
        edges {
          node {
            menuLinkItem {
              ... on ContentfulOurBrands {
                slug
                pageTitle
              }
              ... on ContentfulHomePage {
                pageTitle
                slug
              }
              ... on ContentfulContactUs {
                pageTitle
                slug
              }
              ... on ContentfulAboutUs {
                pageTitle
                slug
              }
              ... on ContentfulPress {
                pageTitle
                slug
              }
              ... on ContentfulSuppliers {
                pageTitle
                slug
              }
              ... on ContentfulJobs {
                pageTitle
                slug
              }
              
            }
          }
        }
      }
    }
  
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} navigation={data.allContentfulMainNavigation.edges[0].node.menuLinkItem} />
      <div>
        <main>{children}</main>
        <footer>
          <div className="social-container">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/the-eventa-group" className="linkedin">LinkedIn</a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/eventaparties?lang=en" className="twitter">Twitter</a>
            <a target="_blank" rel="noopener noreferrer" href="https://en-gb.facebook.com/eventaparties/" className="facebook">Facebook</a>
          </div>
          © {new Date().getFullYear()} Eventa Entertainment Group Ltd. All Rights Reserved. Company Number: 04945685 - VAT Number 834994383
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
